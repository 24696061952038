<template>
  <div class="pbf">
     
     <n-dialog-provider>
       <n-message-provider>
         <TabBanners
           :data="data"
           />
       </n-message-provider>
     </n-dialog-provider>

  </div>
</template>

<script setup>

import { NDialogProvider, NMessageProvider } from 'naive-ui'

import TabBanners from '@/pages/brands-manager/components/TabBanners'
  
const props = defineProps({
  data: {
    type: Object    
  }
});

useHead(useNuxtApp().$head.getBrandManager());

</script>

<style scoped>



/**/
 
</style>

<style>

</style>